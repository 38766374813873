import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "12",
  height: "12",
  viewBox: "0 0 12 12",
  fill: "#212529",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M10.5 1.5H1.5V10.5H10.5V1.5ZM0 0V12H12V0H0Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }